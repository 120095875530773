const theme = {
  breakpoints: [40, 52, 64, 90].map(n => n + "em"),
  space: [0, 4, 6, 8, 12, 16, 32, 64],
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#07c",
    overlay: "rgba(0, 0, 0, .2)",
    grandbassin: "#992d2a",
    scrollbar: "#eee",
  },
  fonts: {
    svg: "muli",
    body: "muli, sans-serif",
    heading: "muli, sans-serif",
  },
  lineHeights: {
    body: 1.2,
    heading: 1.2,
    nav: 1.2,
  },
  zIndices: {
    block: 50,
    header: 100,
    modal: 200,
  },
  fontSizes: [
    "1.375rem",
    "1.125rem",
    "1rem",
    "0.875rem",
    "0.75rem",
    "0.625rem",
  ],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 900,
  },
  borders: {
    svg: 3,
    nav: "3px solid black",
    article: "3px solid black",
    modal: "6px solid black",
    home: "2px dashed black",
  },
  sizes: {
    navWidth: "256px",
    navHeight: "96px",
    modalWidth: "40em",
    scrollbar: "16px",
    social: "2.4rem",
  },
  text: {
    title: {
      textTransform: "uppercase",
      lineHeight: 1,
      fontSize: 0,
      fontWeight: 900,
    },
    subtitle: {
      fontSize: 2,
      fontWeight: "normal",
      fontStyle: "italic",
      lineHeight: 1,
      textTransform: "none",
      mb: 4,
    },
    link: {
      color: "inherit",
      textTransform: "uppercase",
      textDecoration: "inherit",
    },
    nav: {
      variant: "text.link",
      fontSize: 2,
      fontWeight: 900,
      lineHeight: "nav",
    },
    article: {
      variant: "text.title",
      fontSize: 5,
      textAlign: "center",
    },
    articleSubtitle: {
      variant: "text.article",
      mt: 3,
      fontSize: 1,
    },
  },
  links: {
    nav: {
      variant: "text.nav",
      display: "block",
      cursor: "pointer",
    },
  },
  buttons: {
    nav: {
      variant: "variants.nav",
      cursor: "pointer",
      borderRadius: 0,
      transition: "all 440ms ease-out",
    },
    navDark: {
      variant: "buttons.nav",
      bg: "text",
      color: "background",
      p: 3,
      pb: 2,
    },
    navLight: {
      variant: "buttons.navDark",
      bg: "background",
      color: "text",
    },
    navSocial: {
      variant: "buttons.nav",
      p: "0 !important",
      bg: "text",
      color: "background",
      "> *": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "social",
        height: "social",
      },
    },
    navToggle: {
      variant: "buttons.nav",
      minHeight: "navHeight",
      "> *": {
        display: "inline-block",
        width: "1em",
      },
    },
    navField: {
      variant: "buttons.nav",
      p: [2, 2, 2, 4],
      pb: [1, 1, 1, 3],
      fontSize: [4, 4, 4, 2],
      h1: {
        fontSize: [3, 3, 3, 0],
      },
      h3: {
        fontSize: [5, 5, 5, 2],
      },
      a: {
        fontSize: [4, 4, 4, 2],
      },
    },
  },
  variants: {
    navGroup: {
      mr: 3,
      mt: [1, 0],
      "&:first-child": {
        mt: 0,
      },
      "&:last-child": {
        mr: 0,
      },
      "&:nth-child(-n+2)": {
        minWidth: "navWidth",
      },
      "> *:first-child": {
        minHeight: "navHeight",
        pb: 0,
      },
    },
    nav: {
      variant: "text.nav",
      display: "block",
      p: 4,
      pb: 3,
      border: "nav",
      bg: "background",
      listStyle: "none",
      mb: 1,
    },
    overlay: {
      background: "overlay",
      cursor: "pointer",
    },
    modal: {
      border: "modal",
      padding: 3,
      background: "background",
      maxWidth: "modalWidth",
      h1: {
        textAlign: "center",
      },
      h2: {
        textAlign: "center",
      },
    },
  },
  styles: {
    root: {
      // uses the theme values provided above
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      height: "100%",
      body: {
        height: "100%",
        "#___gatsby, #gatsby-focus-wrapper": {
          height: "100%",
        },
      },
      "*::-webkit-scrollbar": {
        width: "scrollbar",
        background: "scrollbar",
      },
      "*::-webkit-scrollbar-thumb": {
        background: "text",
      },
      b: {
        fontWeight: "bold",
      },
    },
    h1: {
      variant: "text.title",
      "&:first-of-type": {
        mt: 0,
      },
      // mb: 4,
      // mt: 4,
    },
    h2: {
      variant: "text.title",
      mt: "1.8em",
    },
    h3: {
      variant: "text.subtitle",
      mt: "1em",
    },
    a: {
      variant: "text.link",
    },
    p: {
      textAlign: "justify",
      textTransform: "uppercase",
      "&:first-child": {
        mt: 0,
      },
      "&:empty": {
        height: "1.2em",
      },
      "&:last-child:empty": {
        display: "none",
      },
    },
    hr: {
      variant: "text.title",
      mx: 0,
      mt: "1.2em",
      mb: "3.2em",
      width: ".7em",
      borderTop: "4px solid black",
      borderBottom: "none",
      borderLeft: "none",
      borderRight: "none",
      background: "background",
    },
  },
}

export default theme
