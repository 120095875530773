exports.components = {
  "component---themes-gatsby-theme-samplecm-src-pages-404-tsx": () => import("./../../../../../themes/gatsby-theme-samplecm/src/pages/404.tsx" /* webpackChunkName: "component---themes-gatsby-theme-samplecm-src-pages-404-tsx" */),
  "component---themes-gatsby-theme-samplecm-src-pages-catalogue-tsx": () => import("./../../../../../themes/gatsby-theme-samplecm/src/pages/catalogue.tsx" /* webpackChunkName: "component---themes-gatsby-theme-samplecm-src-pages-catalogue-tsx" */),
  "component---themes-gatsby-theme-samplecm-src-pages-edito-tsx": () => import("./../../../../../themes/gatsby-theme-samplecm/src/pages/edito.tsx" /* webpackChunkName: "component---themes-gatsby-theme-samplecm-src-pages-edito-tsx" */),
  "component---themes-gatsby-theme-samplecm-src-pages-events-tsx": () => import("./../../../../../themes/gatsby-theme-samplecm/src/pages/events.tsx" /* webpackChunkName: "component---themes-gatsby-theme-samplecm-src-pages-events-tsx" */),
  "component---themes-gatsby-theme-samplecm-src-pages-index-tsx": () => import("./../../../../../themes/gatsby-theme-samplecm/src/pages/index.tsx" /* webpackChunkName: "component---themes-gatsby-theme-samplecm-src-pages-index-tsx" */),
  "component---themes-gatsby-theme-samplecm-src-pages-press-tsx": () => import("./../../../../../themes/gatsby-theme-samplecm/src/pages/press.tsx" /* webpackChunkName: "component---themes-gatsby-theme-samplecm-src-pages-press-tsx" */),
  "component---themes-gatsby-theme-samplecm-src-templates-collection-template-tsx": () => import("./../../../../../themes/gatsby-theme-samplecm/src/templates/collection-template.tsx" /* webpackChunkName: "component---themes-gatsby-theme-samplecm-src-templates-collection-template-tsx" */)
}

